'use client';

import { formatDateTimeLong } from '@streetferret/utils';

interface DateTimeProps {
  date?: Date;
  className?: string;
  /**
   * Show the timezone
   */
  withTz?: boolean;
  /**
   * Only show the date, not the time
   */
  hideTime?: boolean;
}
export function DateTime({ date, className, withTz, hideTime }: DateTimeProps) {
  return (
    <span className={className}>
      {date
        ? formatDateTimeLong(date, {
            tz: withTz,
            hideTime,
          })
        : null}
    </span>
  );
}
